import React, { useEffect } from 'react';

const Uses = () => {
    useEffect(() => {
        window.location.replace('https://github.com/Nikodermus/dotfiles');
    }, []);

    return null;
};

export default Uses;
